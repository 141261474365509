import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Scr3 extends React.Component {

    static navigationOptions = {
        title: "Family History"
    }

    static path = "";



    render() {

      const imgFamily = require('../assets/family.png');

      const { navigation } = this.props;
        return <View style={styles.container}>
        <View style={{ }}>
        <Text style={styles.h1} >Family History</Text>
        <Text style={styles.h2} >Do you have a Family History of Scoliosis?</Text>

        <Image    
          source={imgFamily}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />

        </View>

        <View style={styles.buttonContainer}>

<View style={styles.button}>
        <Button
        color="#fe5000"
          title="Yes"
          onPress={() => {
            this.props.navigation.navigate('Scr4', {
              familyHistory: 'yes',
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
</View>



<View style={styles.button}>
        <Button
        color="#fe5000"
          title="No"
          onPress={() => {
            this.props.navigation.navigate('Scr4', {
              familyHistory: 'no',
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
</View>






</View>

      </View>
    }
}



const styles = StyleSheet.create({
  container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: 3,
      flex: 1


  },
  h2: {
    fontSize: 18,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 10,
      margin: 3,
      flex: 2

  },
  buttonContainer: {
    display:"flex",  
      flexDirection: "row", 
      justifyContent: 'center',
      flexWrap: "wrap",
      flex: 3, 
      width: "90%",
      maxWidth: 500
    
      

  },

  button: {
    padding:10,        
    textAlign: 'center',
    flexGrow: 1,
      

  }
})
