import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Scr2 extends React.Component {

    static navigationOptions = {
        title: "Your Age"
    }

    static path = "";



    render() {

      const imgAge = require('../assets/age.png');

      const { navigation } = this.props;
        return <View style={styles.container}>
        <Text style={styles.h1} >Your Age </Text>
        <Text style={styles.h2} >How old are you?</Text>

        <Image    
          source={imgAge}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />

        <View style={styles.buttonContainer}>

        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="0-3"
          onPress={() => {
            this.props.navigation.navigate('Scr3', {
              age: '0-3',
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
        </View>


        <View style={styles.button}>  
        <Button
        color="#fe5000"
          title="4-10"
          onPress={() => {
            this.props.navigation.navigate('Scr3', {
              age: '4-10',
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
        </View>

        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="11-18"
          onPress={() => {
            this.props.navigation.navigate('Scr3', {
              age: '11-18',
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
        </View>

        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="19-59"
          onPress={() => {
            this.props.navigation.navigate('Scr3', {
              age: '18-60',
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
        </View>

        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="60+"
          onPress={() => {
            this.props.navigation.navigate('Scr3', {
              age: '60+',
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />
        </View>

</View>

</View>



    }
}

const styles = StyleSheet.create({
  container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 3,
      flex: 1


  },
  h2: {
    fontSize: 18,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 10,
      margin: 3,
      flex: 2

  },
  buttonContainer: {
    display:"flex",  
    flexDirection: "row", 
    justifyContent: 'center',
    flexWrap: "wrap",
    flex: 3, 
    width: "90%",
    maxWidth: 500
    
      

  },

  button: {
    padding:10,        
    textAlign: 'center',
    
      

  }
})

