import {createStackNavigator} from 'react-navigation-stack';
import {createAppContainer} from 'react-navigation';
import {createBrowserApp} from '@react-navigation/web';

import Feed from "../screens/Feed";
import Profile from "../screens/Profile";
import Scr1 from "../screens/Scr1";
import Scr2 from "../screens/Scr2";
import Scr3 from "../screens/Scr3";
import Scr4 from "../screens/Scr4";
import Scr5 from "../screens/Scr5";
import Scr6 from "../screens/Scr6";
import Scr7 from "../screens/Scr7";
import Scr8 from "../screens/Scr8";
import Scr9 from "../screens/Scr9";
import Scr10 from "../screens/Scr10";
import Results from "../screens/Results";
import Begin from "../screens/Begin";
import Imsel1 from "../screens/Imsel1";
import Imsel2 from "../screens/Imsel2";
import Imsel3 from "../screens/Imsel3";

import {Platform} from "react-native";

const isWeb = Platform.OS === 'web';


const Home = createStackNavigator(
    {

        Begin: Begin,
        Scr1: Scr1,
        Scr2: Scr2,
        Scr3: Scr3,
        Scr4: Scr4,
        Scr5: Scr5,
        Scr6: Scr6,
        Scr7: Scr7,
        Scr8: Scr8,
        Scr9: Scr9,
        Scr10: Scr10,
        Imsel1: Imsel1,
        Imsel2: Imsel2,
        Imsel3: Imsel3,
        Results: Results,

    },
    {
      defaultNavigationOptions: {
  

      headerStyle: {
        backgroundColor: '#0079c8',

      },
      headerTintColor: '#fff',
      headerTitleStyle: {
        fontWeight: 'bold',
            },
        },



    }
);

const container = isWeb ? createBrowserApp(Home): createAppContainer(Home);

export default container;
