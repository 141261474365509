import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Imsel1 extends React.Component {

    static navigationOptions = {
        title: "Image 1"
    }

    static path = "";


    render() {
      const { navigation } = this.props;

      const img_1_m = require('../assets/scoli1-1m.png');
      const img_1_f = require('../assets/scoli1-1f.png');
      const img_2_m = require('../assets/scoli1-2m.png');
      const img_2_f = require('../assets/scoli1-2f.png');
      const img_3_m = require('../assets/scoli1-3m.png');
      const img_3_f = require('../assets/scoli1-3f.png');
      const img_4_m = require('../assets/scoli1-4m.png');
      const img_4_f = require('../assets/scoli1-4f.png');



      let imgpath1;
        if (this.props.navigation.getParam('mf') === 'm') {
            imgpath1 = img_1_f;
            
        }

        else if (this.props.navigation.getParam('mf') === 'f') {
            imgpath1 = img_1_f; 
            
        }



        let imgpath2;
        if (this.props.navigation.getParam('mf') === 'm') {
            imgpath2 = img_2_f;
            
        }

        else if (this.props.navigation.getParam('mf') === 'f') {
            imgpath2 = img_2_f; 
            
        }  

        let imgpath3;
        if (this.props.navigation.getParam('mf') === 'm') {
            imgpath3 = img_3_f;
            
        }

        else if (this.props.navigation.getParam('mf') === 'f') {
            imgpath3 = img_3_f; 
            
        }

        let imgpath4;
        if (this.props.navigation.getParam('mf') === 'm') {
            imgpath4 = img_4_f;
            
        }

        else if (this.props.navigation.getParam('mf') === 'f') {
            imgpath4 = img_4_f; 
            
        }











        return <View style={styles.container}>


        <View style={{ }}>
        <Text style={styles.h1} >Use a Mirror, or ask a Friend</Text>
  <Text style={styles.h2} >Which image best matches the apperance of your hips and waist?</Text>
  <Text style={styles.h3} >Is each side of the waist even, or is one side more prominent?</Text>
  </View>


  <View style={styles.imageButtonContainer}>

     

  <View style={styles.imageButton}>

          <Image
          
              source={imgpath1}
              style={{width: 250, height: 500}}
              resizeMode="cover"
          />

          <Button
            title="Image 1"
            color="#fe5000"
            onPress={() => {
              this.props.navigation.navigate('Imsel2', {
                scoliimg1: '1',
                functionalCapacity: (navigation.getParam('functionalCapacity', 'NO-ID')),
                oneSidedsports: (navigation.getParam('oneSidedsports', 'NO-ID')),
                balletGymnastics: (navigation.getParam('balletGymnastics', 'NO-ID')),
                appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
                appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
                backPain: (navigation.getParam('backPain', 'NO-ID')),
                previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
                familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
                age: (navigation.getParam('age', 'NO-ID')),
                mf: (navigation.getParam('mf', 'NO-ID')),
              });
            }}
          />



  </View>

  
  <View style={styles.imageButton}>





          <Image
              source={imgpath2}
              style={{width: 250, height: 500}}
              resizeMode="cover"
          />


          <Button
            title="Image 2"
            color="#fe5000"
            onPress={() => {
              this.props.navigation.navigate('Imsel2', {
                scoliimg1: '2',
                functionalCapacity: (navigation.getParam('functionalCapacity', 'NO-ID')),
                oneSidedsports: (navigation.getParam('oneSidedsports', 'NO-ID')),
                balletGymnastics: (navigation.getParam('balletGymnastics', 'NO-ID')),
                appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
                appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
                backPain: (navigation.getParam('backPain', 'NO-ID')),
                previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
                familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
                age: (navigation.getParam('age', 'NO-ID')),
                mf: (navigation.getParam('mf', 'NO-ID')),
              });
            }}
          />

  </View>


  <View style={styles.imageButton}>






          <Image
              source={imgpath3}
              style={{width: 250, height: 500}}
              resizeMode="cover"
          />


          <Button
            title="Image 3"
            color="#fe5000"
            onPress={() => {
              this.props.navigation.navigate('Imsel2', {
                scoliimg1: '3',
                functionalCapacity: (navigation.getParam('functionalCapacity', 'NO-ID')),
                oneSidedsports: (navigation.getParam('oneSidedsports', 'NO-ID')),
                balletGymnastics: (navigation.getParam('balletGymnastics', 'NO-ID')),
                appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
                appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
                backPain: (navigation.getParam('backPain', 'NO-ID')),
                previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
                familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
                age: (navigation.getParam('age', 'NO-ID')),
                mf: (navigation.getParam('mf', 'NO-ID')),
              });
            }}
          />

  </View>




  <View style={styles.imageButton}>



          <Image
              source={imgpath4}
              style={{width: 250, height: 500}}
              resizeMode="cover"
          />


          <Button
            title="Image 4"
            color="#fe5000"
            onPress={() => {
              this.props.navigation.navigate('Imsel2', {
                scoliimg1: '4',
                functionalCapacity: (navigation.getParam('functionalCapacity', 'NO-ID')),
                oneSidedsports: (navigation.getParam('oneSidedsports', 'NO-ID')),
                balletGymnastics: (navigation.getParam('balletGymnastics', 'NO-ID')),
                appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
                appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
                backPain: (navigation.getParam('backPain', 'NO-ID')),
                previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
                familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
                age: (navigation.getParam('age', 'NO-ID')),
                mf: (navigation.getParam('mf', 'NO-ID')),
              });
            }}
          />

  </View>





  </View>










        </View>
      }
  }

  const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        margin: 3,
        flex: 1


    },
    h2: {
      fontSize: 18,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
        margin: 3,
        flex: 2

    },
    h3: {
      fontSize: 14,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
        margin: 3,
        flex: 2

    },
    imageButtonContainer: {
      display:"flex",  
      flexDirection: "row", 
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: "wrap",      
      flex: 3, 
      width: "90%"
      
        

    },

    imageButton: {
      padding:10,        
      textAlign: 'center',
      flexGrow: 1,
      flexDirection: "column", 
      justifyContent: 'center',
      alignItems: 'center',
        

    }
})