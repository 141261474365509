import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Scr4 extends React.Component {

    static navigationOptions = {
        title: "Diagnosis"
    }

    static path = "";



    render() {

      const imgPreviousDiagnosis = require('../assets/previousdiagnosis.png');

      const { navigation } = this.props;
        return <View style={styles.container}>
        <View style={{ }}>
        <Text style={styles.h1} >Previous Diagnosis</Text>
        <Text style={styles.h2} >Has a health professional ever told you that you may have Scoliosis?</Text>



        </View>

        <Image    
          source={imgPreviousDiagnosis}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />
      

        <View style={styles.buttonContainer}>



        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="Yes"
          onPress={() => {
            this.props.navigation.navigate('Scr5', {
              previousDiagnosis:'yes',
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID')),
            });
          }}
        />
</View>

  

  <View style={styles.button}>
        <Button
        color="#fe5000"
          title="No"
          onPress={() => {
            this.props.navigation.navigate('Scr5', {
              previousDiagnosis:'no',
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID'))
            });
          }}
        />

</View>


</View>




      </View>
    }
}

const styles = StyleSheet.create({
  container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: 3,
      flex: 1


  },
  h2: {
    fontSize: 18,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 10,
      margin: 3,
      flex: 2

  },
  buttonContainer: {
    display:"flex",  
    flexDirection: "row", 
    justifyContent: 'center',
    flexWrap: "wrap",
    flex: 3, 
    width: "90%",
    maxWidth: 500
      

  },

  button: {
    padding:10,        
    textAlign: 'center',
    flexGrow: 1,
      

  }
})

