import React from 'react';
import {View, Text, StyleSheet, Button, Platform, ScrollView, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Begin extends React.Component {

    static navigationOptions = {
        title: "Begin"
    }

    static path = "";

    render() {
        return <      View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: 20, }}>

        <Image
            source={require('../assets/scolicheck.png')}
            style={{width: "70%", minWidth: 300, minHeight: 100,}}
            resizeMode="contain"
        />


        <Text style={styles.h1}>Welcome to Scoli Check</Text>

        <Text style={{fontSize:18}}>Please read and accept our terms of use</Text>





                <Button
                style={{paddingTop: 10}}
                  title="Accept and Start"
                  color="#fe5000"
                  onPress={() => {
                    this.props.navigation.navigate('Scr1');
                  }}
                />


                <ScrollView style={{ flex: 1, paddingTop: 20, height:50, width:"70%" }}>
                          <Text style={{fontSize:18, fontWeight: 'bold',}}>Scoli Check Disclaimer</Text>

                          <Text>{'\n'}This app has been created for educational purposes only and is provided “as is”. The objective of this app is to inform the public about the risk of Scoliosis and the importance of early detection and treatment, based on scientific research.{'\n'}{'\n'}
                        The indication of risk, results, or suggestions made by this app are in no way a substitute for a professional opinion. Therefore, we take no responsibility or liability for any course of treatment that you decide to take or not take based on these results. Irrespective of the indications provided by this app, If you have questions or concerns about Scoliosis we suggest you consult a medical professional or a specialist scoliosis practitioner. {'\n'}{'\n'}
                          This application does not collect, store or transmit any personal information.{'\n'}{'\n'}
                          
                          Please note: ScoliCheck is still in development - this is a public Beta version. 


                          </Text>

                        </ScrollView>

      </View>
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    h1: {
      fontSize: 25,
      fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',
        height:50,
        marginTop: 10,
    },
    h2: {
      fontSize: 18,
        justifyContent: 'center',
        alignItems: 'center',
        height:30,
        marginBottom: 10,
    }
})
