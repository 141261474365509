import React from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
import Header from './components/Header';
import Home from './navigation/Home';



export default function App() {
  return (
    <View style={styles.container}>
      <Home/>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: '#fff',
    backgroundColor:'000',
  },
  h1: {
    fontSize: 19,
    fontWeight: 'bold',
  },
  activeTitle: {
    color: 'red',
  },
});
