import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Scr5 extends React.Component {

    static navigationOptions = {
        title: "Back Pain"
    }

    static path = "";



    render() {
      const imgBackPain = require('../assets/backpain.png');
      const { navigation } = this.props;
        return <View style={styles.container}>
        <View style={{ }}>
        <Text style={styles.h1} >Back Pain</Text>
        <Text style={styles.h2} >Have you experienced back pain in the last 6 months?</Text>



        </View>

        <Image    
          source={imgBackPain}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />

        <View style={styles.buttonContainer}>

        <View style={styles.button}>
        <Button
        color="#fe5000"
          title="Yes"
          onPress={() => {
            this.props.navigation.navigate('Scr6', {
              backPain: 'yes',
              previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID')),
            });
          }}
        />
</View>


  

  <View style={styles.button}>
        <Button
        color="#fe5000"
          title="No"
          onPress={() => {
            this.props.navigation.navigate('Scr6', {
              backPain: 'no',
              previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID')),
            });
          }}
        />
</View>


</View>





      </View>
    }
}

const styles = StyleSheet.create({
  container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: 3,
      flex: 1


  },
  h2: {
    fontSize: 18,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 10,
      margin: 3,
      flex: 2

  },
  buttonContainer: {
    display:"flex",  
    flexDirection: "row", 
    justifyContent: 'center',
    flexWrap: "wrap",
    flex: 3, 
    width: "90%",
    maxWidth: 500
    
      

  },

  button: {
    padding:10,        
    textAlign: 'center',
    flexGrow: 1,
      

  }
})
