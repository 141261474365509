import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'



const isWeb = Platform.OS === 'web';

export default class Scr1 extends React.Component {

    static navigationOptions = {
        title: "Your Gender"
    }

    static path = "";

    render() {
      const imgGender = require('../assets/gender.png');
       
       return <View style={styles.container}>
        <View style={{ }}>
        <Text style={styles.h1} >Your Gender</Text>
        <Text style={styles.h2} >Are you Male, or Female?</Text>
        </View>

        <Image    
          source={imgGender}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />

 
 
 


<View style={styles.buttonContainer}>
        
        <View style={styles.button}>
        <Button 
        color="#fe5000"
          title="Male"
          onPress={() => {
            this.props.navigation.navigate('Scr2', {
                            mf: 'm',
            });
          }}
        />
       </View>

 

       <View style={styles.button}>
        <Button style
        color="#fe5000"
          title="Female"
          onPress={() => {
            this.props.navigation.navigate('Scr2', {
                            mf: 'f',
            });
          }}
        />
        </View>

    </View>
</View>


    }
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    h1: {
      fontSize: 40,
      fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'centr',
        margin: 3,
        flex: 1


    },
    h2: {
      fontSize: 18,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: 10,
        margin: 3,
        flex: 2

    },
    buttonContainer: {
      display:"flex",  
      flexDirection: "row", 
      justifyContent: 'center',
      flexWrap: "wrap",
      flex: 3, 
      width: "90%",
      maxWidth: 500

      
      
      
        

    },

    button: {
      padding:10,        
      textAlign: 'center',
      flexGrow: 1,
        

    }
})
