import React from 'react';
import {View, Text, StyleSheet, Button, Platform} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Results extends React.Component {

    static navigationOptions = {
        title: "Your Results"
    }

    static path = "";



    render() {
       const genderMale = 'You indicated that you were male, statistically males are at lower risk of Scoliosis (75% of cases are female) however, males can, and do, develop scoliosis. In cases which occur under the age of 6 Scoliosis is more common in males. ';
       const genderFemale = 'You indicated that you were female, statistically females are at a higher risk of Scoliosis, up to 75% of all scoliosis cases are females. ';


let genderscore = 0;
let genderMessage;
 if (this.props.navigation.getParam('mf') === 'm') {
     genderMessage = genderMale;
     genderscore = 1;
 }

 else if (this.props.navigation.getParam('mf') === 'f') {
     genderMessage = genderFemale;
     genderscore = 3;
 }

       const age03 = 'You indicated that you were between 0 and 3, rates of Scoliosis amongst this age group are low, but present a very high risk for worsening of the Scoliotic curve where one exists. About 1% of Scoliosis cases occur at this age. ';
       const age410 = 'You indicated that you were between 4 and 10, about 10-15% of Scoliosis cases In children are detected in this age group. Curves detected at this age are highly likely to worsen, perhaps rapidly. ';
       const age1118 = 'Scoliosis that appears in otherwise healthy 10 to 18-year olds is termed adolescent idiopathic scoliosis – this is the most common form of scoliosis by far. Between 3 and 4% of teenagers suffer from Scoliosis. The high rate of growth during this time means that the risk of progression is very high. ';
       const age1959 = 'Adults between the age of 18 and 60 are at the lowest risk of a new case of scoliosis developing. In some instances, cases of scoliosis which are not detected in adolescence are detected in adulthood.';
       const age60plus = 'Up to 30% of adults over 60 suffer from a form of scoliosis known as “de-novo” scoliosis.';

let agescore = 0;


       let message;
        if (this.props.navigation.getParam('age') === '0-3') {
            message = age03;
            agescore = 2;
        }

        else if (this.props.navigation.getParam('age') === '4-10') {
            message = age410;
            agescore = 2;
        }

        else if (this.props.navigation.getParam('age') === '11-18') {
            message = age1118;
            agescore = 3;
        }

        else if (this.props.navigation.getParam('age') === '19-59') {
            message = age1959;
            agescore = 1;
        }

        else if (this.props.navigation.getParam('age') === '60+') {
            message = age60plus;
            agescore = 3;
        }

        const familyHistoryyes = 'A family history of Scoliosis is one of the best indicators of scoliosis risk. It is currently believed that genetics play an important role in the development of scoliosis. Those with family members with scoliosis should be especially vigilant.';
        const familyHistoryno = 'Research suggests that those with a family history of Scoliosis are at a higher risk, however, this does not mean that scoliosis can not develop in individuals without a family history.';


                let familyHistoryscore = 0;


                 let familyHistorymessage;
                  if (this.props.navigation.getParam('familyHistory') === 'yes') {
                      familyHistorymessage = familyHistoryyes;
                      familyHistoryscore= 3

                  }

                  else if (this.props.navigation.getParam('familyHistory') === 'no') {
                      familyHistorymessage = familyHistoryno;
                      familyHistoryscore= 0

                  }

                  const previousDiagnosisyes = 'You indicated that someone has previously told you you may have Scoliosis. While Scoliosis can only be formally diagnosed by a Scoliosis professional or a Doctor, you should seek a professional evaluation if someone has suggested you may have Scoliosis. If you have been diagnosed with Scoliosis, but have been told to "wait and see" how it progresses, you should seek a consultation with scoliosis professional.';
                  const previousDiagnosisno = 'You indicated that no one has ever suggested you may have Scoliosis, however, only a Scoliosis professional or Doctor can rule Scoliosis in, or out.';



                                  let previousDiagnosisscore = 0;


                                   let previousDiagnosismessage;
                                    if (this.props.navigation.getParam('familyHistory') === 'yes') {
                                        previousDiagnosismessage = previousDiagnosisyes;
                                        previousDiagnosisscore= 3

                                    }

                                    else if (this.props.navigation.getParam('familyHistory') === 'no') {
                                        previousDiagnosismessage = previousDiagnosisno;
                                        previousDiagnosisscore= 0

                                    }


                                    const balletGymnasticsyes = 'You indicated that you participate in Ballet or Gymnastics. Research indicates that those who participate in these activities are up to 12 times more likely to suffer from Scoliosis. Some research has suggested that as much as 30% of participants suffer from Scoliosis. ';
                                    const balletGymnasticsno = 'You do not participate in Ballet or Gymnastics - this might matter, because some research suggests that as many as 30% of participants in ballet and gymnastics suffer from scoliosis. ';



                                                    let balletGymnasticsscore = 0;


                                                     let balletGymnasticsmessage;
                                                      if (this.props.navigation.getParam('balletGymnastics') === 'yes') {
                                                          balletGymnasticsmessage = balletGymnasticsyes;
                                                          balletGymnasticsscore= 3

                                                      }

                                                      else if (this.props.navigation.getParam('balletGymnastics') === 'no') {
                                                          balletGymnasticsmessage = balletGymnasticsno;
                                                          balletGymnasticsscore= 0

                                                      }


                      const backPainyes = 'You indicated that you have been suffering from back pain. While there are a huge number of conditions which can cause back pain, some recent research does suggest that scoliosis can also cause back pain. It is estimated, for example, that Scoliosis patients have between a 3 and 5 fold increased risk of back pain in the upper and middle right part of the back. In patients under 21 who are recommended treatment for back pain, scoliosis is the most common underlying diagnosis. It would not be accurate to say that back pain is a fair indicator of scoliosis, however, you should always have pain investigated by a specialist. ';
                                             const backPainno = 'You indicated that you have not experienced back pain recently - Some research has indicated that scoliosis may cause back pain. ';



                                                         let backPainscore = 0;


                                                          let backPainmessage;
                                                           if (this.props.navigation.getParam('backPain') === 'yes') {
                                                               backPainmessage = backPainyes;
                                                               backPainscore= 3

                                                           }

                                                           else if (this.props.navigation.getParam('backPain') === 'no') {
                                                             backPainmessage = backPainno;
                                                             backPainscore= 0
                                                           }

const appearanceChangeyes = 'You indicated that you have recently noticed a change in your appearance. Scoliosis often progresses rapidly during a growth spurt, meaning that changes are sometimes noticed in a short space of time. Scoliosis can worsen quickly – if you have noticed a change in your spine which seem to have occurred quickly, it is important to see a specialist as soon as possible ';
const appearanceChangeno = 'Scoliosis can often progress rapidly, especially during a growth spurt – continue to monitor your appearance and check for signs. ';



            let appearanceChangescore = 0;


             let appearanceChangemessage;
              if (this.props.navigation.getParam('appearanceChange') === 'yes') {
                  appearanceChangemessage = appearanceChangeyes;
                  appearanceChangescore= 3

              }

              else if (this.props.navigation.getParam('appearanceChange') === 'no') {
                appearanceChangemessage = appearanceChangeno;
                appearanceChangescore= 0
              }



const appearanceHappyyes = 'You indicate that you are happy with your appearance ';
const appearanceHappyno = 'You said that you would not be happy to live with the way your back looks today, while its normal (especially for teenagers) to feel underconfident about their appearance from time to time, research does suggest that scoliosis patients often suffer from low self-confidence and unhappiness with their appearance. ';



            let appearanceHappyscore = 0;


             let appearanceHappymessage;
              if (this.props.navigation.getParam('appearanceHappy') === 'yes') {
                  appearanceHappymessage = appearanceHappyyes;
                  appearanceHappyscore= 0

              }

              else if (this.props.navigation.getParam('appearanceHappy') === 'no') {
                appearanceHappymessage = appearanceHappyno;
                appearanceHappyscore= 2
              }


              


              const functionalCapyes = 'You said that you often find it more difficult than others to do physical tasks such as walking up stairs. There are many conditions which can account for this (as well as simply your fitness level) – but research does suggest that scoliosis patients often find these kinds of tasks more difficult. ';
              const functionalCapno = 'You indicated you don’t have any significant issues with functional tasks.';
              
              
              
                          let functionalCapscore = 0;
              
              
                           let functionalCapmessage;
                            if (this.props.navigation.getParam('functionalCapacity') === 'yes') {
                                functionalCapmessage = functionalCapyes;
                                functionalCapscore= 2
              
                            }
              
                            else if (this.props.navigation.getParam('functionalCapacity') === 'no') {
                              functionalCapmessage = functionalCapno;
                              functionalCapscore= 0
                            }
              
              





const scoliImg11 = 'You chose image 1 on screen 1, this image is representative of an individual without Scoliosis.';
const scoliImg12 = 'You chose image 2 on screen 1, this image is representative of an individual who may suffer from mild Scoliosis';
const scoliImg13 = 'You chose image 3 on screen 1, this image is representative of an individual who may suffer from moderate Scoliosis';
const scoliImg14 = 'You chose image 4 on screen 1, this image is representative of an individual who may suffer from severe Scoliosis';



    let scoliImg1score = 0;


     let scoliImg1message;
      if (this.props.navigation.getParam('scoliimg1') === '1') {
          scoliImg1message = scoliImg11;
          scoliImg1score= 1

      }

      else if (this.props.navigation.getParam('scoliimg1') === '2') {
          scoliImg1message = scoliImg12;
          scoliImg1score= 2

      }

      else if (this.props.navigation.getParam('scoliimg1') === '3') {
          scoliImg1message = scoliImg13;
          scoliImg1score= 3

      }

      else if (this.props.navigation.getParam('scoliimg1') === '4') {
        scoliImg1message = scoliImg14;
        scoliImg1score= 4

    }




      const scoliImg21 = 'You chose image 1 on screen 2, this image is representative of an individual without Scoliosis.';
      const scoliImg22 = 'You chose image 2 on screen 2, this image is representative of an individual who may suffer from Scoliosis';
      const scoliImg23 = 'You chose image 3 on screen 2, this image is representative of an individual appears to have severe Scoliosis';



          let scoliImg2score = 0;


           let scoliImg2message;
            if (this.props.navigation.getParam('scoliimg2') === '1') {
                scoliImg2message = scoliImg21;
                scoliImg2score= 1

            }

            else if (this.props.navigation.getParam('scoliimg2') === '2') {
                scoliImg2message = scoliImg22;
                scoliImg2score= 2

            }

            else if (this.props.navigation.getParam('scoliimg2') === '3') {
                scoliImg2message = scoliImg23;
                scoliImg2score= 3

            }


const scoliImg31 = 'You chose image 1 on screen 3, this image is representative of an individual without Scoliosis.';
const scoliImg32 = 'You chose image 2 on screen 3, this image is representative of an individual who may suffer from mild Scoliosis';
const scoliImg33 = 'You chose image 3 on screen 3, this image is representative of an individual who may suffer from moderate Scoliosis';
const scoliImg34 = 'You chose image 4 on screen 3, this image is representative of an individual who may suffer from severe Scoliosis';



  let scoliImg3score = 0;


   let scoliImg3message;
    if (this.props.navigation.getParam('scoliimg3') === '1') {
        scoliImg3message = scoliImg31;
        scoliImg3score= 1

    }

    else if (this.props.navigation.getParam('scoliimg3') === '2') {
        scoliImg3message = scoliImg32;
        scoliImg3score= 2

    }

    else if (this.props.navigation.getParam('scoliimg3') === '3') {
        scoliImg3message = scoliImg33;
        scoliImg3score= 3

    }

    else if (this.props.navigation.getParam('scoliimg3') === '4') {
        scoliImg3message = scoliImg34;
        scoliImg3score= 4

    }












let score = (previousDiagnosisscore + familyHistoryscore + balletGymnasticsscore + agescore + genderscore + backPainscore + functionalCapscore + appearanceHappyscore + appearanceChangescore + scoliImg1score + scoliImg2score + scoliImg3score);


      const { navigation } = this.props;

        return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', paddingTop: 20, }}>
        <Text style={styles.h1} >Your results</Text>
        <Text style={styles.h2} >Here are your results</Text>
       {/* <Text style={styles.h3} >Your Scoli Score is</Text><Text style={styles.italic}>{score}</Text> */}




<View style={{width:"70%" }}>
{/* <Text style={styles.h1} >More Detail{'\n'}</Text>*/}

<Text style={styles.h2} >{'\n'}Vital Statistics</Text>
<Text style={styles.h3} >{'\n'}Your Gender</Text><Text style={styles.italic}>{genderMessage}</Text>
<Text style={styles.h3} >{'\n'}Your Age</Text><Text style={styles.italic}>{message}</Text>

<Text style={styles.h2} >{'\n'}Case History</Text>
<Text style={styles.h3} >{'\n'}Your Family History</Text><Text style={styles.italic}>{familyHistorymessage}</Text>
<Text style={styles.h3} >{'\n'}A Previous Diagnosis</Text><Text style={styles.italic}>{previousDiagnosismessage}</Text>
<Text style={styles.h3} >{'\n'}Back Pain</Text><Text style={styles.italic}>{backPainmessage}</Text>

<Text style={styles.h2} >{'\n'}Lifestyle</Text>
<Text style={styles.h3} >{'\n'}Ballet and Gymnastics</Text><Text style={styles.italic}>{balletGymnasticsmessage}</Text>
<Text style={styles.h3} >{'\n'}Functional Capacity</Text><Text style={styles.italic}>{functionalCapmessage}</Text>

<Text style={styles.h2} >{'\n'}Appearance</Text>
<Text style={styles.h3} >{'\n'}Image 1</Text><Text style={styles.italic}>{scoliImg1message}</Text>
<Text style={styles.h3} >{'\n'}Image 2</Text><Text style={styles.italic}>{scoliImg2message}</Text>
<Text style={styles.h3} >{'\n'}Image 3</Text><Text style={styles.italic}>{scoliImg3message}</Text>
<Text style={styles.h3} >{'\n'}Your opinion</Text><Text style={styles.italic}>{appearanceHappymessage}</Text>
<Text style={styles.h3} >{'\n'}A Change in Apperance</Text><Text style={styles.italic}>{appearanceChangemessage}{'\n'}</Text>

<Text style={styles.h2} >{'\n'}What now?</Text>
<Text style={styles.italic}>If any of the above factors indicate you may have scoliosis we would recommend a scoliosis consultation. The UK Scoliosis clinic (who developed this app) is a leading provider of non-surgical scoliosis treatment in the UK. We offer scoliosis consultation at our clinic, or online – for more information visit the supporting website for the app at doihavescoliosis.com </Text>
<Text style={styles.h2} >{'\n'}</Text>


</View>



</View>








  }




}







const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    h1: {
      fontSize: 25,
      fontWeight: 'bold',
        justifyContent: 'center',
        alignItems: 'center',

    },
    h2: {
      fontSize: 18,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color:"#fe5000",
    },
    h3: {
      fontSize: 14,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',


    },
    italic: {
        fontStyle: 'italic',

    },
    result:{


    }
})
