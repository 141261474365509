import React from 'react';
import {View, Text, StyleSheet, Button, Platform, Image} from 'react-native';
import {Link} from '@react-navigation/web'

const isWeb = Platform.OS === 'web';

export default class Scr8 extends React.Component {

    static navigationOptions = {
        title: "Ballet and Gymnastics"
    }

    static path = "";



    render() {
      const imgBallet = require('../assets/ballet.png');
      const { navigation } = this.props;
        return <View style={styles.container}>
        <View style={{ }}>
        <Text style={styles.h1} >Ballet and Gymnastics</Text>
        <Text style={styles.h2} >Do you participate in Ballet, or Gymnastics?</Text>
        </View>

        <Image     
          source={imgBallet}
          style={{minWidth: 300, minHeight: 200, marginTop: 10}}
          resizeMode="contain"
      />

        <View style={styles.buttonContainer}>

        

  <View style={styles.button}>
        <Button
        color="#fe5000"
          title="Yes"
          onPress={() => {
            this.props.navigation.navigate('Scr9', {
              balletGymnastics: 'yes',
              appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
              appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
              backPain: (navigation.getParam('backPain', 'NO-ID')),
              previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID')),
            });
          }}
        />
</View>

 

<View style={styles.button}>
        <Button
        color="#fe5000"
          title="No"
          onPress={() => {
            this.props.navigation.navigate('Scr9', {
              balletGymnastics: 'no',
              appearanceChange:(navigation.getParam('appearanceChange', 'NO-ID')),
              appearanceHappy:(navigation.getParam('appearanceHappy', 'NO-ID')),
              backPain: (navigation.getParam('backPain', 'NO-ID')),
              previousDiagnosis: (navigation.getParam('previousDiagnosis', 'NO-ID')),
              familyHistory: (navigation.getParam('familyHistory', 'NO-ID')),
              age: (navigation.getParam('age', 'NO-ID')),
              mf: (navigation.getParam('mf', 'NO-ID')),
            });
          }}
        />
</View>

</View>






      </View>
    }
}

const styles = StyleSheet.create({
  container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
  },
  h1: {
    fontSize: 40,
    fontWeight: 'bold',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: 3,
      flex: 1


  },
  h2: {
    fontSize: 18,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 10,
      margin: 3,
      flex: 2

  },
  buttonContainer: {
    display:"flex",  
      flexDirection: "row", 
      justifyContent: 'center',
      flexWrap: "wrap",
      flex: 3, 
      width: "90%",
      maxWidth: 500
      

  },

  button: {
    padding:10,        
    textAlign: 'center',
    flexGrow: 1,
      

  }
})
